<template>
  <tr :style='ventureConditionColor'>
    <td class='border-r'>{{ dailyPerformance.created_on }}</td>
    <td class='border-r'>{{ dailyPerformance.fund_code }}</td>
    <td class='border-r'>{{ dailyPerformance.fund_name }}</td>
    <td class='border-r'>{{ dailyPerformance.fund_short_name }}</td>
    <td class='border-r'>{{ dailyPerformance.fund_target_asset }}</td>
    <td v-if='isShow && isViewAssetInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.total_assets_amount) }}</td>
    <td v-if='isShow && isViewAssetInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.nav_amount) }}</td>
    <td v-if='isShow && isViewAssetInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.shares) }}</td>
    <td v-if='isShow && isViewAssetInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.tax_nav_amount) }}</td>
    <td v-if='isShow && isViewAssetInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.nonresidential_tax_nav_amount) }}</td>
    <td v-if='isShow && isViewAssetInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.original_amount) }}</td>
    <td v-if='isShow && isViewAssetInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.price_per_share) }}</td>
    <td v-if='isShow && isViewAssetInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.tax_price_per_share) }}</td>
    <td v-if='isShow && isViewAssetInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.nonresidential_price_per_share) }}</td>
    <td v-if='isShow && isViewVentureInfo' class='border-r'>{{ dailyPerformance.venture_start_date }}</td>
    <td v-if='isShow && isViewVentureInfo' class='border-r'>{{ dailyPerformance.venture_end_date }}</td>
    <td v-if='isShow && isViewVentureInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.venture_new_shares_close_value) }}</td>
    <td v-if='isShow && isViewVentureInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.venture_exclude_new_shares_close_value) }}</td>
    <td v-if='isShow && isViewVentureInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.venture_total_assets_amount) }}</td>
    <td v-if='isShow && isViewVentureInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.venture_nav_amount) }}</td>
    <td v-if='isShow && isViewVentureInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.venture_new_shares_ratio) }}</td>
    <td v-if='isShow && isViewVentureInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.venture_exclude_new_shares_ratio) }}</td>
    <td v-if='isShow && isViewVentureInfo' class='text-right border-r'>{{ totalVentureSharesRatio }}</td>
    <td v-if='isViewHighYieldInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.hy_nav_amount) }}</td>
    <td v-if='isViewHighYieldInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.hy_junk_bond_n_konex_close_value) }}</td>
    <td v-if='isViewHighYieldInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.hy_junk_bond_n_konex_ratio) }}</td>
    <td v-if='isViewHighYieldInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.hy_konex_close_value) }}</td>
    <td v-if='isViewHighYieldInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.hy_konex_ratio) }}</td>
    <td v-if='isViewHighYieldInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.hy_except_junk_bond_close_value) }}</td>
    <td v-if='isViewHighYieldInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.hy_except_junk_bond_ratio) }}</td>
    <td v-if='isViewHighYieldInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.hy_bond_close_value) }}</td>
    <td v-if='isViewHighYieldInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.hy_bond_ratio) }}</td>
    <td v-if='isViewHighYieldInfo' class='text-right border-r'>{{ numberStyle(dailyPerformance.hy_bond_n_konex_close_value) }}</td>
    <td v-if='isViewHighYieldInfo' class='text-right'>{{ numberStyle(dailyPerformance.hy_bond_n_konex_ratio) }}</td>
  </tr>
</template>

<script>

export default {
  name: 'DailyPerformanceRow',
  props: [
    'dailyPerformance',
    'isShow',
    'isViewAssetInfo',
    'isViewVentureInfo',
    'isViewHighYieldInfo',
  ],
  computed: {
    totalSharesRatio () {
      return this.dailyPerformance.total_shares_ratio ? Math.round(this.dailyPerformance.total_shares_ratio * 100) / 100 : ''
    },
    totalVentureSharesRatio () {
      return this.dailyPerformance.venture_total_shares_ratio ? Math.round(this.dailyPerformance.venture_total_shares_ratio * 100) / 100 : ''
    },
    ventureConditionColor () {
      return this.dailyPerformance.venture_check ? '' : 'color: red; background-color: pink;'
    }
  },
  methods: {
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
  }
}
</script>

<style lang='scss' scoped>
  td {
    @apply py-3 pl-2 pr-6 border-b cursor-pointer text-gray-700 whitespace-no-wrap;
  }

</style>
